/**
 * Maps an error to a string message
 *
 * @param {Error} error the error to map
 * @return {String} error message
 */
var toString = error => {
  var {
    graphQLErrors,
    message
  } = error;
  return graphQLErrors && graphQLErrors.length ? graphQLErrors.map(_ref => {
    var {
      message
    } = _ref;
    return message;
  }).join(', ') : message;
};

/**
 * A function to derive an error string from an array of errors.
 */
export var deriveErrorMessage = errors => {
  var errorCollection = [];
  for (var error of errors) {
    if (error) {
      errorCollection.push(toString(error));
    }
  }
  return errorCollection.join(', ');
};